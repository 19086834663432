<template>
  <div class="deviceList">
    <div class="page-title">设备列表</div>
    <div class="row-me row-center flex-wrap mT20">
      <div class="row-me row-center mR20 mB10">
        <div>设备名称</div>
        <div class="w200 mL10">
          <el-input placeholder="请输入名称" style="width: 100%;" clearable v-model="name"></el-input>
        </div>
      </div>
      <div class="row-me row-center mR20 mB10">
        <div>设备编码</div>
        <div class="w200 mL10">
          <el-input placeholder="请输入设备编码" style="width: 100%;" clearable v-model="code"></el-input>
        </div>
      </div>
      <div class="row-me row-center mR20 mB10">
        <div>在线状态</div>
        <div class="w200 mL10">
          <el-select placeholder="请选择" style="width: 100%;" filterable clearable v-model="status">
            <el-option v-for="item in [{id:0,name:'离线'},{id:1,name:'在线'}]" :key="item.id" :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="row-me row-center mR20 mB10">
        <div>绑定状态</div>
        <div class="w200 mL10">
          <el-select placeholder="请选择" style="width: 100%;" filterable clearable v-model="bindingType">
            <el-option v-for="item in [{id:1,name:'已绑定'},{id:2,name:'未绑定'}]" :key="item.id" :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="row-me row-center mR20 mB10">
        <div>所属组织</div>
        <div class="w200 mL10">
          <el-select placeholder="请选择" style="width: 100%;" filterable clearable v-model="org">
            <el-option v-for="item in orgArray" :key="item.deptId" :label="item.deptName" :value="item.deptId">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="row-me row-center mR20 mB10">
        <div>创建时间</div>
        <div class="w350 mL10">
          <el-date-picker type="datetimerange" range-separator="至" start-placeholder="开始时间"
                          value-format="YYYY-MM-DD HH:mm:ss" end-placeholder="结束时间" style="width: 100%;" clearable
                          v-model="date">
          </el-date-picker>
        </div>
      </div>
    </div>
    <div class="row-me row-center">
      <div class="btn-blue w100 btn-height40" @click="clickSearch">查询</div>
      <div class="btn-blank w100 btn-height40 mL20" @click="clickClear">重置</div>
      <div class="btn-blue w100 btn-height40 mL50" @click="clickAdd">新增设备</div>
      <div class="btn-blue w100 btn-height40 mL20" @click="clickBatchImport">批量导入</div>
      <div class="btn-blue w100 btn-height40 mL20" @click="clickBatchExport">批量导出</div>
      <div class="btn-blue w150 btn-height40 mL20" @click="clickBatchWarning">批量设置预警范围</div>
      <div class="btn-blank w100 btn-height40 mL20" @click="clickBatchDelete">批量删除</div>
    </div>
    <div class="column-me mT20">
      <div>
        <el-table :data="tableData" border header-cell-class-name="table-header-style"
                  cell-class-name="table-cell-style" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="60" align="center"/>
          <el-table-column type="index" label="序号" width="80" align="center"/>
          <el-table-column prop="id" label="ID" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="name" label="设备名称" align="center" show-overflow-tooltip
                           width="120"></el-table-column>
          <el-table-column prop="code" label="编码" align="center" show-overflow-tooltip width="170"></el-table-column>
          <el-table-column prop="model" label="型号" align="center" show-overflow-tooltip width="140"></el-table-column>
          <el-table-column prop="postName" label="所属组织" align="center" show-overflow-tooltip
                           width="120"></el-table-column>
          <el-table-column prop="bidName" label="绑定用户" align="center" show-overflow-tooltip
                           width="120"></el-table-column>
          <el-table-column prop="createdTime" label="创建时间" align="center" show-overflow-tooltip
                           width="200"></el-table-column>
          <el-table-column label="在线状态" align="center" show-overflow-tooltip width="100">
            <template #default="scope">
              {{ scope.row.status === 0 ? '离线' : scope.row.status === 1 ? '在线' : '' }}
            </template>
          </el-table-column>
          <el-table-column label="绑定状态" align="center" show-overflow-tooltip width="100">
            <template #default="scope">
              {{ $method.isEmpty(scope.row.userId) ? '未绑定' : '已绑定' }}
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" show-overflow-tooltip width="300" fixed="right">
            <template #default="scope">
              <div class="row-me center-all">
                <div class="blue-text" @click="clickBind(scope.row)">绑定用户</div>
                <div class="blue-text mL10" @click="clickSet(scope.row)">行程预警设置</div>
                <div class="blue-text mL10" @click="clickEdit(scope.row)">编辑</div>
                <el-popconfirm title="您确认删除吗？" width="250" @confirm="clickDelete(scope.row)">
                  <template #reference>
                    <div class="red-text mL10">删除</div>
                  </template>
                </el-popconfirm>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="mT10">
        <el-pagination @current-change="handleCurrentChange" :current-page.sync="pageNo"
                       layout="total, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>

    <el-dialog title="绑定用户" v-model="visibleBind" width="600px" top="10vh" :destroy-on-close="true">
      <div class="column-me row-center">
        <div class="row-me row-center mT20">
          <div class="mR20"><span class="red-star">*</span>选择用户</div>
          <div class="w300">
            <el-select placeholder="请选择" style="width: 100%;" filterable remote clearable
                       :loading="loading" :remote-method="remoteMethod" v-model="detailItem.userId">
              <el-option v-for="item in userArray" :key="item.id" :label="item.nickname" :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="w100Per row-me row-center flex-end1 mT50">
          <div class="btn-blank w100 btn-height40 mR20" @click="visibleBind=false">取消</div>
          <div class="btn-blue w100 btn-height40" @click="clickSaveBind">确定</div>
        </div>
      </div>
    </el-dialog>

    <el-dialog title="新增设备" v-model="visibleAdd" width="600px" top="10vh" :destroy-on-close="true">
      <div class="column-me row-center">
        <div class="row-me row-center">
          <div class="mR20"><span class="red-star">*</span>设备名称</div>
          <div class="w300">
            <el-input placeholder="请输入" style="width: 100%;" clearable v-model="detailItem.name"></el-input>
          </div>
        </div>
        <div class="row-me row-center mT20">
          <div class="mR20"><span class="red-star">*</span>设备型号</div>
          <div class="w300">
            <el-input placeholder="请输入" style="width: 100%;" clearable v-model="detailItem.model"></el-input>
          </div>
        </div>
        <div class="row-me row-center mT20">
          <div class="mR20"><span class="red-star">*</span>设备编码</div>
          <div class="w300">
            <el-input placeholder="请输入" style="width: 100%;" clearable v-model="detailItem.code"></el-input>
          </div>
        </div>
        <div class="row-me row-center mT20">
          <div class="mR20"><span class="red-star">*</span>所属组织</div>
          <div class="w300">
            <el-select placeholder="请选择" style="width: 100%;" filterable clearable v-model="detailItem.postId">
              <el-option v-for="item in orgArray" :key="item.deptId" :label="item.deptName" :value="item.deptId">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="w100Per row-me row-center flex-end1 mT50">
          <div class="btn-blank w100 btn-height40 mR20" @click="visibleAdd=false">取消</div>
          <div class="btn-blue w100 btn-height40" @click="clickSaveAdd">确定</div>
        </div>
      </div>
    </el-dialog>

    <el-dialog title="批量导入" v-model="visibleImport" width="1000px" top="10vh" :destroy-on-close="true">
      <importExcel name="设备导入模板" :download-url="downloadUrl" request-url="/equipments/watchImport"
                   @importSuccess="importSuccess"></importExcel>
    </el-dialog>

    <el-dialog title="行程预警设置" v-model="visibleSet" width="700px" top="10vh" :destroy-on-close="true">
      <div class="column-me row-center">
        <div class="row-me row-center mT20">
          <div class="mR20"><span class="red-star">*</span>心率范围</div>
          <div class="w200">
            <el-input placeholder="请输入最小值" style="width: 100%;" clearable v-model="min1"
                      @input="min1=$method.checkInput(min1,2)"></el-input>
          </div>
          <div class="mLR10">-</div>
          <div class="w200">
            <el-input placeholder="请输入最大值" style="width: 100%;" clearable v-model="max1"
                      @input="max1=$method.checkInput(max1,2)"></el-input>
          </div>
        </div>
        <div class="row-me row-center mT20">
          <div class="mR20"><span class="red-star">*</span>血压范围</div>
          <div class="w200">
            <el-input placeholder="请输入最小值" style="width: 100%;" clearable v-model="min2"
                      @input="min2=$method.checkInput(min2,2)"></el-input>
          </div>
          <div class="mLR10">-</div>
          <div class="w200">
            <el-input placeholder="请输入最大值" style="width: 100%;" clearable v-model="max2"
                      @input="max2=$method.checkInput(max2,2)"></el-input>
          </div>
        </div>
        <div class="row-me row-center mT20">
          <div class="mR20"><span class="red-star">*</span>血氧范围</div>
          <div class="w200">
            <el-input placeholder="请输入最小值" style="width: 100%;" clearable v-model="min3"
                      @input="min3=$method.checkInput(min3,2)"></el-input>
          </div>
          <div class="mLR10">-</div>
          <div class="w200">
            <el-input placeholder="请输入最大值" style="width: 100%;" clearable v-model="max3"
                      @input="max3=$method.checkInput(max3,2)"></el-input>
          </div>
        </div>
        <div class="w100Per row-me row-center flex-end1 mT50">
          <div class="btn-blank w100 btn-height40 mR20" @click="visibleSet=false">取消</div>
          <div class="btn-blue w100 btn-height40" @click="clickSaveWarning">确定</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import importExcel from "@/components/import/importExcel.vue";

export default {
  components: {
    importExcel
  },
  data() {
    return {
      name: '',
      code: '',
      status: '',
      bindingType: '',
      org: '',
      orgArray: [],
      date: [],
      tableData: [],
      pageNo: 1,
      total: 0,
      multipleSelection: [],
      visibleBind: false,
      visibleAdd: false,
      detailItem: {},
      loading: false,
      visibleImport: false,
      visibleSet: false,
      min1: '',
      min2: '',
      min3: '',
      max1: '',
      max2: '',
      max3: '',
      userArray: [],
      userArrayCopy: [],
      downloadUrl: '',//模板地址
    }
  },
  // 侦听器
  watch: {},
  // 计算属性
  computed: {},
  // html加载完成之前
  created() {
    this.getOrgArray()
    this.clickSearch()
  },
  // html加载完成后执行。
  mounted() {

  },
  // 事件方法执行
  methods: {
    clickSearch() {
      this.pageNo = 1
      this.getData()
    },
    clickClear() {
      this.name = ''
      this.code = ''
      this.status = ''
      this.bindingType = ''
      this.org = ''
      this.date = []
      this.clickSearch()
    },
    clickAdd() {
      this.detailItem = {}
      this.visibleAdd = true
    },
    clickEdit(item) {
      this.detailItem = this.$method.deepClone(item)
      this.visibleAdd = true
    },
    clickDelete(item) {
      this.$confirm("您确定要删除吗？", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$postForm('/equipments/watchDeletedAll', {
          ids: [item.id],
        }).then((res) => {
          if (res) {
            this.$message.success('删除成功')
            this.getData()
          }
        }).catch((error) => {
          console.log(error)
        })
      }).catch(() => {
      });
    },
    clickSaveAdd() {
      if (this.$method.isEmpty(this.detailItem.name)) {
        this.$message.error("请输入设备名称")
        return
      }
      if (this.$method.isEmpty(this.detailItem.model)) {
        this.$message.error("请输入设备型号")
        return
      }
      if (this.$method.isEmpty(this.detailItem.code)) {
        this.$message.error("请输入设备编码")
        return
      }
      if (this.$method.isEmpty(this.detailItem.postId)) {
        this.$message.error("请选择设备组织")
        return
      }
      let param = {
        name: this.detailItem.name,
        model: this.detailItem.model,
        code: this.detailItem.code,
        postId: this.detailItem.postId,
      }
      if (!this.$method.isEmpty(this.detailItem.id)) {
        param.id = this.detailItem.id
      }
      this.$postForm('/equipments/equipmentSave', param).then((res) => {
        if (res) {
          this.$message.success(res.msg)
          this.clickSearch()
          this.visibleAdd = false
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    clickBatchImport() {
      this.$post('/equipments/equipmentConfig', {}).then((res) => {
        if (res) {
          this.downloadUrl = res.data.configValue
        }
      }).catch((error) => {
        console.log(error)
      })
      this.visibleImport = true
    },
    importSuccess(e) {
      this.visibleImport = false
      this.$message.success('导入成功')
      if (e === 'refresh') {
        this.clickSearch()
      }
    },
    clickBatchExport() {
      let ids = this.multipleSelection.map(item => {
        return item.id
      })
      this.$postForm('/equipments/watchDeriveAll', {
        ids: ids
      }).then((res) => {
        if (res) {
          window.open(res.data)
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    clickBatchWarning() {
      if (this.multipleSelection.length === 0) {
        this.$message.error("请先选择设备")
        return
      }
      this.detailItem = null
      this.visibleSet = true
    },
    clickSet(item) {
      this.detailItem = this.$method.deepClone(item)
      this.min1 = this.detailItem.heartRateMin
      this.max1 = this.detailItem.heartRateMax
      this.min2 = this.detailItem.bloodPressureMin
      this.max2 = this.detailItem.bloodPressureMax
      this.min3 = this.detailItem.bloodOxygenMin
      this.max3 = this.detailItem.bloodOxygenMax
      this.visibleSet = true
    },
    clickBatchDelete() {
      if (this.multipleSelection.length === 0) {
        this.$message.error("请先选择设备")
        return
      }
      this.$confirm("您确定要删除吗？", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = this.multipleSelection.map(item => {
          return item.id
        })
        this.$postForm('/equipments/watchDeletedAll', {
          ids: ids,
        }).then((res) => {
          if (res) {
            this.$message.success('删除成功')
            this.getData()
          }
        }).catch((error) => {
          console.log(error)
        })
      }).catch(() => {
      });
    },
    handleCurrentChange(pageNo) {
      this.pageNo = pageNo
      this.getData()
    },
    handleSelectionChange(e) {
      this.multipleSelection = e
    },
    clickBind(item) {
      this.getUserArray()
      this.detailItem = this.$method.deepClone(item)
      this.visibleBind = true
    },
    clickSaveBind() {
      this.$postForm('/equipments/bindUser', {
        id: this.detailItem.id,
        userId: this.detailItem.userId,
      }).then((res) => {
        if (res) {
          this.$message.success('绑定成功')
          this.visibleBind = false
          this.getData()
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.userArray = this.userArrayCopy.filter(item => {
            return item.nickname.toLowerCase().indexOf(query.toLowerCase()) !== -1 || item.phone.toLowerCase().indexOf(query.toLowerCase()) !== -1;
          });
        }, 200);
      } else {
        this.userArray = this.$method.deepClone(this.userArrayCopy);
      }
    },
    //保存预警设置
    clickSaveWarning() {
      if (this.$method.isEmpty(this.min1) || this.$method.isEmpty(this.max1)) {
        this.$message.error("请输入心率范围")
        return
      }
      if (this.$method.isEmpty(this.min2) || this.$method.isEmpty(this.max2)) {
        this.$message.error("请输入血压范围")
        return
      }
      if (this.$method.isEmpty(this.min3) || this.$method.isEmpty(this.max3)) {
        this.$message.error("请输入血氧范围")
        return
      }
      let arr = []
      if (this.$method.isEmpty(this.detailItem)) {//批量
        for (let item of this.multipleSelection) {
          arr.push({
            id: item.id,
            heartRateMin: this.min1,
            heartRateMax: this.max1,
            bloodPressureMin: this.min2,
            bloodPressureMax: this.max2,
            bloodOxygenMin: this.min3,
            bloodOxygenMax: this.max3,
          })
        }
      } else {//单个
        arr.push({
          id: this.detailItem.id,
          heartRateMin: this.min1,
          heartRateMax: this.max1,
          bloodPressureMin: this.min2,
          bloodPressureMax: this.max2,
          bloodOxygenMin: this.min3,
          bloodOxygenMax: this.max3,
        })
      }
      this.$post('/equipments/AllwatchSet', JSON.stringify(arr)).then((res) => {
        if (res) {
          this.$message.success('设置成功')
          this.visibleSet = false
          this.min1 = ''
          this.min2 = ''
          this.min3 = ''
          this.max1 = ''
          this.max2 = ''
          this.max3 = ''
          this.getData()
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    //获取组织下拉
    getOrgArray() {
      this.$get('/system/dept/list', {}).then((res) => {
        if (res) {
          this.orgArray = res.data
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    getData() {
      this.$postForm('/equipments/equipmentPage', {
        name: this.name,
        code: this.code,
        status: this.status,
        bindingType: this.bindingType,
        organizationId: this.org,
        startTime: this.date.length === 2 ? this.date[0] : '',
        endTime: this.date.length === 2 ? this.date[1] : '',
        pageNumber: this.pageNo,
        pageSize: 10,
      }).then((res) => {
        if (res) {
          this.total = Number(res.data.total)
          this.tableData = res.data.records
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    //获取用户下拉
    getUserArray() {
      this.$postForm('/users/userAll', {}).then((res) => {
        if (res) {
          this.userArray = res.data
          this.userArrayCopy = res.data
        }
      }).catch((error) => {
        console.log(error)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.deviceList {
  height: 100%;
  margin: 20px;
  padding: 20px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
</style>
